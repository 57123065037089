<template>
  <div class="all-shops">
    <div class="container">
      <div class="all-shops__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}
        </locale-router-link>
        <a class="crumbs-link all-shops-link"> {{ $t("shops") }}</a>
      </div>
    </div>

    <shops-map
      v-if="shopsList"
      :shops-list="shopsList"
      :is-show-shop-types="true"
    />
    <div v-if="!shopsList" class="loader__container">
      <div class="loader"></div>
    </div>

    <div v-show="slidesList && slidesList.length" class="all-shops__stores">
      <div class="container">
        <h2 class="all-shops__stores-title title">
          {{ $t("meetStores") }}
        </h2>
        <div class="all-shops__slider swiper">
          <swiper
            ref="swiperRef"
            class="swiper-wrapper"
            :options="swiperOption"
          >
            <swiper-slide v-for="(item, index) in slidesList" :key="index">
              <locale-router-link
                v-if="item"
                :to="`news/${item.id}`"
                tag="div"
                class="all-shops__slide swiper-slide"
              >
                <img
                  :src="
                    $helpers.getAbsolutePath(
                      item.attributes.image.data.attributes.url
                    )
                  "
                  :alt="item.attributes.name"
                  class="all-shops__slide-img"
                />
                <time class="all-shops__slide-time">{{ getDate(item) }}</time>
                <h3 class="all-shops__slide-title">
                  {{ item.attributes.Header }}
                </h3>
              </locale-router-link>
            </swiper-slide>
          </swiper>
          <div class="all-shops__slider-nav">
            <button
              @click="prevSlide"
              class="all-shops__slide-prev all-shops__slider-btn"
            >
              <svg
                width="62"
                height="62"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="31"
                  cy="31"
                  r="30.5"
                  fill="white"
                  stroke="#B7B7B7"
                />
                <path
                  d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                  fill="#B5B5B5"
                />
              </svg>
            </button>
            <button
              @click="nextSlide"
              class="all-shops__slide-next all-shops__slider-btn"
            >
              <svg
                width="62"
                height="62"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="31"
                  cy="31"
                  r="30.5"
                  fill="white"
                  stroke="#B7B7B7"
                />
                <path
                  d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                  fill="#B5B5B5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <weekly-products-list />
  </div>
</template>

<script>
import { getNewsByCatId } from "@/api/news";
import { mapState } from "vuex";
import { getShopsByFilters } from "@/api/shop";
export default {
  name: "PageShops",
  components: {
    WeeklyProductsList: () =>
      import("@/components/products/WeeklyProductsList.vue"),
    ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
  },
  data() {
    return {
      shopsList: null,
      slidesList: null,
      swiperOption: {
        spaceBetween: 40,
        slideTo: 1,
        slidesPerView: 4,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          100: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperRef;
    },
  },

  created() {
    if (this.selectedCity?.id) {
      getShopsByFilters(this.selectedCity.id).then((shops) => {
        this.shopsList = shops;
        // this.slidesList = this.shopsList.slice(0, 20);
      });
    }

    getNewsByCatId(3).then((newsShop) => {
      this.slidesList = newsShop;
    });
  },
  watch: {
    selectedCity: {
      handler(city) {
        getShopsByFilters(city.id).then((shops) => {
          this.shopsList = shops;
        });
        getNewsByCatId(3).then((newsShop) => {
          this.slidesList = newsShop;
        });
      },
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      if (
        this.swiper?.swiperInstance.slides.length - 7 ===
        this.swiper?.swiperInstance.realIndex
      ) {
        this.slidesList = [
          ...this.slidesList,
          ...this.shopsList.slice(20, this.shopsList.length),
        ];
      }
      this.swiper.swiperInstance.slideNext();
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt);
      return new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr);
    },
  },
};
</script>

<style scoped></style>
